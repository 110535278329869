export default {
  SET_USERNAME(state, username) {
    console.log("username set");
    state.username = username;
  },
  SET_TOKEN(state, token) {
    console.log("token_set");
    state.token = token;
  }
};
