export default {
  addFelanmalan({ rootState }, post) {
    const AuthStr = "AR-JWT ".concat(rootState.user.token);
    console.log("Add felanmalan, token" + AuthStr);
    console.log("data in:" + post);
    console.log("data konf:" + post.konferensrum);

    let kablar =''
    const createdPost = {
      values: {
        Submitter: "Allen",
        "Short Description__c": "Felanmälan för konferensrum: "+post.konferensrum,
        Status: "New",
        Konferensrum: post.konferensrum,
        Kablar: ((post.isKablar) ? 0:'') ,

        Projektor:((post.isProjektor) ? 0:''),
        Monitor:((post.isMonitor) ? 0:'')

      
      
      
      }
    };

    return this.$axios
      .$post(
        process.env.baseUrl + "arsys/v1/entry/LM%3AFelanmalan_motesrum",
        createdPost,
        {
          headers: { Authorization: AuthStr, "X-Requested-With": "aw" }
        }
      )
      .then((response) => {
        console.log("We submitted data.");
          })
      .catch(e => {
        console.log("Något gick fel");
        console.log(e);
      });
  }
};
