export default () => ({
  token: null,
  instanceid:'',
  requestid: '',
  username: null,
  email: null,
  firstname: "John",
  lastname: "Doe",
  function: "DEVELOPPER",
  description:
    "My imagination is my own limit. Trust on you and open your spirit beyond the sky.",
  citation:
    "Imagination is more important than knowledge. Knowledge is limited. Imagination encircles the world."
});
