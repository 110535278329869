export default {
  async setUsername({ commit }, user) {
    commit("SET_USERNAME", user);
  },
   setToken({commit}, token) {
     console.log("setting token action",token);
    commit("SET_TOKEN",token);
  },
   arlogin({ commit,dispatch }, aUser) {
    return new Promise((resolve,reject) =>
    {
      console.log("loggar in i AR");
      console.log("user: " + aUser.username);
      console.log("password: " + aUser.password);
      var qs = require("qs");
      var ausername = aUser.username;
      var apassword = aUser.password;
      var data2 = qs.stringify({
        username: ausername,
        password: apassword
      });

      const headers = { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" };
      // localhost:8081/
      this.$axios
        .post(process.env.baseUrl + "jwt/login", data2, headers)
        .then(response => {
          console.log("logged in to ar server");
          dispatch("setToken", response.data);
          //this.commit("user/SET_TOKEN", response.data);
          resolve(response.data);
        })
        .catch(function(error) {
          console.log("This is an error");
          console.log(error);
          reject(error);
        });
    })}
};
